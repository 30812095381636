import { computed, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useStoresList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refStoresListTable = ref(null);

  // filter options
  const storesOptions = [
    { label: "Actif", key: true },
    { label: "Inactif", key: false },
  ];

  // Table Handlers
  const tableColumns = [
    { label: "Nº", key: "index", sortable: true },
    { label: "libellé", key: "name", sortable: true },
    { label: "description", key: "description" },
    { label: "Statut", key: "is_active", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const storeStatusFilter = ref(null);

  const storesList = computed(() => {
    return store.getters["storeManagement/all"];
  });

  const totalStores = computed(() => {
    const meta = store.getters["storeManagement/meta"];
    return meta.total ?? 0;
  });

  const dataMeta = computed(() => {
    const localItemsCount = refStoresListTable.value
      ? refStoresListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStores.value,
    };
  });

  const refetchData = () => {
    refStoresListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, storeStatusFilter], () => {
    refetchData();
  });

  const fetchStoresList = (ctx, callback) => {
    store
      .dispatch("storeManagement/fetchStoresList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_active: storeStatusFilter.value,
      })
      .then(() => {
        callback(storesList.value);
      });
  };

  const deleteStore = async (storeID) => {
    const { data, status } = await store.dispatch(
      "storeManagement/destroyStore",
      {
        id: storeID,
      }
    );
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "Le magasin a été supprimé avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  return {
    fetchStoresList,
    tableColumns,
    perPage,
    currentPage,
    totalStores,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStoresListTable,
    refetchData,
    deleteStore,

    storeStatusFilter,
    storesOptions,
  };
}
