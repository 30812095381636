<template>
  <component :is="storeData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="storeData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données de magasins</h4>
      <div class="alert-body">
        Pas de magasin trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-stores-list' }">
          la liste des magasins
        </b-link>
        pour d'autres magasins.
      </div>
    </b-alert>

    <b-tabs v-if="storeData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Magasin</span>
        </template>
        <handle-store-tab-general
          class="mt-2 pt-75"
          :init-store-data.sync="storeData"
          @data-handled="$router.push({ name: 'apps-stores-list' })"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleStoreTabGeneral from "./HandleStoreTabGeneral.vue";
import useStoresList from "../stores-list/useStoresList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleStore",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleStoreTabGeneral,
  },
  setup() {
    const blankStoreData = {
      name: "",
      location: "",
      phone: "",
      description: "",
    };

    const storeData = ref(JSON.parse(JSON.stringify(blankStoreData)));

    const fetchStore = () => {
      store
        .dispatch("storeManagement/fetchStore", { id: router.currentRoute.params.id })
        .then((store) => {
          storeData.value = store;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            storeData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchStore();
      }
    });

    return {
      storeData,
    };
  },
};
</script>

<style></style>
